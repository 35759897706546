import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const CookieAlert = () => {
  useEffect(() => {
    ReactGA.initialize('G-SFFJWZ4BHT', {
      gaOptions: {
        anonymizeIp: false,
        cookieExpires: 31536000,
      },
      testMode: false,
    })
  }, [])

  return null
}

export default CookieAlert
