import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Footer, Header } from './components'

import activeLanguages from './constants/languages'

import 'animate.css/animate.css'
import './index.scss'

const Layout = () => {
  const { urlLang } = useParams()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { changeLanguage } = i18n

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (Object.keys(activeLanguages).includes(urlLang)) {
      changeLanguage(urlLang)

      document.documentElement.lang = urlLang
    } else {
      navigate(`/en${window.location.pathname}`, { replace: true })
    }
  }, [urlLang])

  return (
    <main>
      <Header />

      <div className="main-content-with-footer">
        <div className="main-content">
          <div className="flex-row">
            <div className="col-12 no-padding">
              <Outlet />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  )
}

export default Layout
